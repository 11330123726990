@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-regular-cyrillic.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-regular-cyrillic.woff") format("woff");
  unicode-range: U+4??;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-regular-greek-coptic.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-regular-greek-coptic.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin.woff") format("woff");
  unicode-range: U+20-7F, U+A0-FF, U+20AC;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-a.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-a.woff") format("woff");
  unicode-range: U+100-17F;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-b.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-regular-latin-ext-b.woff") format("woff");
  unicode-range: U+180-24F;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-bold-cyrillic.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-bold-cyrillic.woff") format("woff");
  unicode-range: U+4??;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-bold-greek-coptic.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-bold-greek-coptic.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin.woff") format("woff");
  unicode-range: U+20-7F, U+A0-FF, U+20AC;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-a.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-a.woff") format("woff");
  unicode-range: U+100-17F;
}

@font-face {
  font-display: swap;
  font-family: Proxima Nova;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-b.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/proxima-nova-bold-latin-ext-b.woff") format("woff");
  unicode-range: U+180-24F;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 600;
  src: url("https://a.poki-cdn.com/fonts/torus-semibold-latin.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-semibold-latin.woff") format("woff");
  unicode-range: U+20-7F, U+A0-FF, U+20AC;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 600;
  src: url("https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-a.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-a.woff") format("woff");
  unicode-range: U+100-17F;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 600;
  src: url("https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-b.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-semibold-latin-ext-b.woff") format("woff");
  unicode-range: U+180-24F;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/torus-bold-latin.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-bold-latin.woff") format("woff");
  unicode-range: U+20-7F, U+A0-FF, U+20AC;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/torus-bold-latin-ext-a.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-bold-latin-ext-a.woff") format("woff");
  unicode-range: U+100-17F;
}

@font-face {
  font-display: swap;
  font-family: Torus;
  font-weight: 700;
  src: url("https://a.poki-cdn.com/fonts/torus-bold-latin-ext-b.woff2") format("woff2"), url("https://a.poki-cdn.com/fonts/torus-bold-latin-ext-b.woff") format("woff");
  unicode-range: U+180-24F;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

/*# sourceMappingURL=index.40f31483.css.map */
